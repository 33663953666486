














































































































import { apiBrandDel, apiBrandLists, apiBrandStatus } from '@/api/goods'
import { RequestPaging } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
@Component({
  components: {
    LsDialog,
    LsPagination,
    ExportData,
  },
})
export default class Brand extends Vue {
  queryObj = {
    name: '',
  }
  pager = new RequestPaging()
  apiBrandLists = apiBrandLists
  getList(): void {
    this.pager.request({
      callback: apiBrandLists,
      params: {
        ...this.queryObj,
      },
    })
  }
  handleReset() {
    this.queryObj = {
      name: '',
    }
    this.getList()
  }
  handleStatus(value: number, id: number) {
    apiBrandStatus({
      id,
      is_show: value,
    }).then(() => {
      this.getList()
    })
  }

  handleDelete(id: number) {
    apiBrandDel({ id }).then(() => {
      this.getList()
    })
  }

  created() {
    this.getList()
  }
}
